
import { StaticRoomActions } from "@/store/vm/static-room/enums";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "static-room-list",
  components: {},
  props: {
    widgetClasses: String,
  },
  emits: ["del"],
  data() {
    return {
      loadingModule: true,
    };
  },
  setup(props, { emit }) {
    const store = useStore();
    const checked = ref(false);
    const { t } = useI18n();

    onMounted(() => {
      store.dispatch(StaticRoomActions.GET_ALL_STATIC_ROOM).catch((e) => {
        console.log(e);
      });
    });

    const list = computed(() => {
      return store.state.StaticRoomModule.list;
    });

    const count = computed(() => {
      return store.state.StaticRoomModule.list.length;
    });

    const delStaticRoom = (staticRoomId) => {
      emit("del", staticRoomId);
    };

    const lang = {
      edit: t("common.edit"),
      delete: t("common.delete"),
    };

    return {
      list,
      checked,
      count,
      delStaticRoom,
      lang,
    };
  },
});
