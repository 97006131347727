
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import List from "@/views/static-room/List.vue";
import { defineComponent, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { StaticRoomActions } from "@/store/vm/static-room/enums";

interface ComponentData {
  staticRoomIdModal: number | boolean;
}

export default defineComponent({
  name: "staticRoom",
  props: {
    widgetClasses: String,
  },
  components: {
    List,
  },
  data(): ComponentData {
    return {
      staticRoomIdModal: false,
    };
  },
  methods: {
    onReset() {
      this.staticRoomIdModal = false;
    },
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Thiền phòng tĩnh", ["Thiền phòng"]);
      MenuComponent.reinitialization();
    });

    const onDel = (staticRoomId) => {
      Swal.fire({
        title: "Bạn muốn xóa thiền phòng?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const resDel = await actionDelete(staticRoomId);
          console.log("resDel");
          console.log(resDel);
        }
      });
    };

    const actionDelete = async (staticRoomId) => {
      return store
        .dispatch(StaticRoomActions.DELETE, staticRoomId)
        .catch((e) => {
          console.log(e);
        });
    };

    return {
      onDel,
    };
  },
});
